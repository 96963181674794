import { h, createApp } from 'vue';
import singleSpaVue from 'single-spa-vue';
import { createPinia } from 'pinia';
import router from './router';
import './styles/base-styles.scss';
import App from './App.vue';
import 'quasar/dist/quasar.css';
import iconSet from 'quasar/icon-set/mdi-v7';
import '@quasar/extras/material-icons/material-icons.css';
import Notify from 'quasar/src/plugins/Notify.js';import Quasar from 'quasar/src/vue-plugin.js';import Loading from 'quasar/src/plugins/Loading.js';;

const pinia = createPinia();
const vueLifecycles = singleSpaVue({
	createApp,
	appOptions: {
		render() {
			return h(App, {
				// single-spa props are available on the "this" object. Forward them to your component as needed.
				// https://single-spa.js.org/docs/building-applications#lifecycle-props
				// if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
				/*
		name: this.name,
		mountParcel: this.mountParcel,
		singleSpa: this.singleSpa,
		*/
			});
		},
	},
	handleInstance(app) {
		app.use(pinia);
		app.use(router);
		app.use(Quasar, {
			iconSet: iconSet,
			config: {
				brand: {
					primary: 'orange',
					secondary: '#673ab7',
					accent: 'darkorange',

					dark: '#121212',
					'dark-page': '#1a1815',
					light: '#FFF',
					positive: '#21ba45',
					negative: '#d50000',
					info: '#31CCEC',
					warning: '#fcc00d',
				},
			},
			plugins: { Notify, Loading },
		});
	},
});
export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
