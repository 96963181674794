import script from "./WeightDataMatchingRow.vue?vue&type=script&setup=true&lang=ts"
export * from "./WeightDataMatchingRow.vue?vue&type=script&setup=true&lang=ts"

import "./WeightDataMatchingRow.vue?vue&type=style&index=0&id=b30e4d70&lang=css"

const __exports__ = script;

export default __exports__
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/.pnpm/vue-cli-plugin-quasar@5.0.2/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon});
