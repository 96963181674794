import script from "./OverweightDataMatchingDetails.vue?vue&type=script&lang=ts&setup=true"
export * from "./OverweightDataMatchingDetails.vue?vue&type=script&lang=ts&setup=true"

import "./OverweightDataMatchingDetails.vue?vue&type=style&index=0&id=12e4d68f&lang=scss"

const __exports__ = script;

export default __exports__
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../node_modules/.pnpm/vue-cli-plugin-quasar@5.0.2/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToolbar,QToolbarTitle,QInput,QBtn,QIcon,QTooltip,QMenu,QToggle,QTable,QTr,QTd});
