import script from "./QGenericSelect.vue?vue&type=script&lang=ts&setup=true"
export * from "./QGenericSelect.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../node_modules/.pnpm/vue-cli-plugin-quasar@5.0.2/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBadge,QSelect,QItem,QItemSection,QChip,QAvatar,QItemLabel});
